import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { SG_FETCH_PRODUCT_PACKAGE, RESET_PRODUCT_PACKAGE } from "constants/actions";
import { Header, Icon, Input } from "semantic-ui-react";
import { format, set } from "date-fns";

const Details = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const [templateId, setTemplateId] = useState(null);
  const [templateDetails, setTemplateDetails] = useState(null);
  const [productList, setProductList] = useState(null);

  useEffect(() => {
    setTemplateId(id);
    dispatch({ type: RESET_PRODUCT_PACKAGE });
    if (id) {
      dispatch({ type: SG_FETCH_PRODUCT_PACKAGE, payload: { id } });
    }
  }, [dispatch, id]);

  const { get_product_package } = useSelector(
    (state) => ({ get_product_package: state.product_package }),
    shallowEqual
  );

  useEffect(() => {
    if (get_product_package && templateId) {
      setTemplateDetails(get_product_package[templateId]);
      setProductList(get_product_package[templateId]?.product_list);
    }
  }, [get_product_package, templateId]);

  const BackArrow = () => navigate(`/app/product`);

  return (
    <>
      <Header as="h2">
        <StyledIcon name="arrow left" onClick={BackArrow} />
        Details of {templateDetails?.name}
      </Header>
      <DetailRow label="Created">
        {templateDetails?.created_at &&
          format(new Date(templateDetails?.created_at), "dd-MMM-yyyy hh:mm a")}
      </DetailRow>
      <DetailRow label="Name">{templateDetails?.name}</DetailRow>
      <DetailRow label="Sort Order">{templateDetails?.sort_order}</DetailRow>

      <DetailRow label="Last Updated">
        {templateDetails?.updated_at &&
          format(new Date(templateDetails?.updated_at), "dd-MMM-yyyy hh:mm a")}
      </DetailRow>
      <SectionDiv lastSection>
      <FormSection>
          <LabelHead>Product in Package</LabelHead>
          {productList && Object.keys(productList).map((sortOrder) => (
            <ProductItem key={sortOrder}>
              <DetailRow label="Product Name">
                {productList[sortOrder]?.name}
              </DetailRow>
              <DetailRow label="Sort Order">
                {productList[sortOrder]?.sort_order}
              </DetailRow>
              <DetailRow label="Stage">
                {productList[sortOrder]?.product_stage}
              </DetailRow>
               
            </ProductItem>
          ))}
        </FormSection>
      </SectionDiv>
    </>
  );
};

const DetailRow = ({ label, children }) => (
  <RowContainer>
    <LabelCell>{label}:</LabelCell>
    <ValueCell>{children}</ValueCell>
  </RowContainer>
);

export default Details;

const SectionDiv = styled.div`
  padding: 10px 0px;
  margin: 10px 0px;
  margin-bottom: ${(props) => (props.lastSection ? "100px" : "20px")};
`;

const StyledIcon = styled(Icon)`
  cursor: pointer;
`;

const RowContainer = styled.div`
  display: flex;
  padding: 10px 0;
  border-bottom: 1px solid #ddd;
`;

const LabelCell = styled.div`
  font-weight: bold;
  width: 150px;
`;

const ValueCell = styled.div`
  flex: 1;
`;

const FormSection = styled.div`
  margin-bottom: 15px;
  width: 100%;
`;

const LabelHead = styled.label`
  font-weight: bold;
  margin-top: 25px;
  margin-bottom: 5px;
  display: block;
  font-size: 16px;
`;

const StyledInput = styled(Input)`
  width: 100%;
`;

const ProductItem = styled.div`
  margin: 10px;
  padding: 10px;
`;
