import { call, put, all, takeLatest } from "redux-saga/effects";
import * as api from "constants/api";

import {
    RUN_MODEL_OUTCOME,
    SG_RUN_MODEL_OUTCOME,
    GET_PREDICTIVE_MODEL_PENDING,
    // GET_PREDICTIVE_MODEL,
    // SG_GET_PREDICTIVE_MODEL,
    // DELETE_PREDICTIVE_MODEL,
    // SG_DELETE_PREDICTIVE_MODEL,
    // FETCH_PREDICTIVE_MODEL,
    // SG_FETCH_PREDICTIVE_MODEL,
    // EDIT_PREDICTIVE_MODEL,
    // SG_EDIT_PREDICTIVE_MODEL,
    // ADD_PREDICTIVE_MODEL,
    // SG_ADD_PREDICTIVE_MODEL,
    // CLEAR_PREDICTIVE_MODEL,
    // RESET_PREDICTIVE_MODEL,
  ALL_ERRORS,
} from "constants/actions";

function* runprocess(action) {
  yield put({ type: GET_PREDICTIVE_MODEL_PENDING });
  try {
    const json = yield call(api.RUN_MODEL_OUTCOME, action.payload);
    yield put({ type: RUN_MODEL_OUTCOME, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, message: e.response });
  }
}

export function* processRun() {
  yield takeLatest(SG_RUN_MODEL_OUTCOME, runprocess);
}

// function* loadprocess(action) {
//   yield put({ type: GET_PREDICTIVE_MODEL_PENDING });
//   // console.log('action.payload', action.payload)
//   try {
//     const json = yield call(api.GET_PREDICTIVE_MODEL, action.payload);
//     yield put({ type: GET_PREDICTIVE_MODEL, payload: json.data });
//   } catch (e) {
//     yield put({ type: ALL_ERRORS, message: e.response });
//   }
// }

// export function* processLoad() {
//   yield takeLatest(SG_GET_PREDICTIVE_MODEL, loadprocess);
// }

// function* fetchprocess(action) {
//     yield put({ type: GET_PREDICTIVE_MODEL_PENDING });
//   try {
//     const json = yield call(api.FETCH_PREDICTIVE_MODEL, action.payload);
//     yield put({ type: FETCH_PREDICTIVE_MODEL, payload: json.data });
//   } catch (e) {
//     yield put({ type: ALL_ERRORS, message: e.response });
//   }
// }

// export function* processFetch() {
//   yield takeLatest(SG_FETCH_PREDICTIVE_MODEL, fetchprocess);
// }

// function* updateprocess(action) {
//     yield put({ type: GET_PREDICTIVE_MODEL_PENDING });
//   try {
//     const json = yield call(api.EDIT_PREDICTIVE_MODEL, action.payload);
//     yield put({ type: EDIT_PREDICTIVE_MODEL, payload: json.data });
//   } catch (e) {
//     yield put({ type: ALL_ERRORS, message: e.response });
//   }
// }

// export function* processUpdate() {
//   yield takeLatest(SG_EDIT_PREDICTIVE_MODEL, updateprocess);
// }

// function* addprocess(action) {
//     yield put({ type: GET_PREDICTIVE_MODEL_PENDING });
//   try {
//     const json = yield call(api.ADD_PREDICTIVE_MODEL, action.payload);
//     yield put({ type: ADD_PREDICTIVE_MODEL, payload: json.data });
//   } catch (e) {
//     yield put({ type: ALL_ERRORS, message: e.response });
//   }
// }

// export function* processAdd() {
//   yield takeLatest(SG_ADD_PREDICTIVE_MODEL, addprocess);
// }

// function* deleteprocess(action) {
//   try {
//     const json = yield call(api.DELETE_PREDICTIVE_MODEL, action.payload);
//     yield put({ type: DELETE_PREDICTIVE_MODEL, payload: json.data });
//   } catch (e) {
//     yield put({ type: ALL_ERRORS, message: e.response });
//   }
// }

// export function* processDelete() {
//   yield takeLatest(SG_DELETE_PREDICTIVE_MODEL, deleteprocess);
// }

export default function* index() {
  yield all([
    processRun(),
    // processLoad(),
    // processFetch(),
    // processUpdate(),
    // processAdd(),
    // processDelete(),
  ]);
}
