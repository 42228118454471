import React from 'react'
import { Routes, Route } from 'react-router-dom';
import List from './List';
import Details from './Details';
import AddEdit from './AddEdit';
import styled from 'styled-components';

import Generate from './Generate';

function PredictiveModel() {
  return (
    <Container>
      <StyledRow>
        <StyledColumn>
          <StyledHeader>
            Predictive Model
            
          </StyledHeader>
          <Subheader>
              Generate predict model for organizations.
            </Subheader>
        </StyledColumn>
      </StyledRow>
      <Generate />
      {/* <Routes>
        <Route path="/" element={<List />} />
        <Route path="details/:id" element={<Details />} />
        <Route path="new" element={<AddEdit />} />
        <Route path="edit/:id" element={<AddEdit />} />
      </Routes> */}
    </Container>
  );
}

export default PredictiveModel;

const Container = styled.div`
  margin-top: 50px;
`;

const StyledRow = styled.div`
  padding: 20px;
  display: flex;
  justify-content: center;
`;

const StyledColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledHeader = styled.div`
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  
`;

const Subheader = styled.div`
  margin-top: 10px;
  margin-bottom: 20px;
`;
